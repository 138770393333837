import { Modal } from '../modals/descriptor/Modal';
import { isReCaptchaValid, removeRecaptchaError, setRecaptchaError } from './ReCaptchaActions';

export const initForms = () => {
    initContactForm();
};

function initContactForm() {
    const formSelector = '[data-contact-us-form]';
    const form = document.querySelector(formSelector);
    if (!form) return;

    const modal = new Modal();

    form.addEventListener('submit', async function (event) {
        event.preventDefault();

        const formData = new FormData(form);
        const values = Object.fromEntries(formData.entries());

        if (!validateForm(values)) {
            modal.open('error-message');
            return;
        }

        if (isReCaptchaValid()) {
            removeRecaptchaError(formSelector);
        } else {
            setRecaptchaError(formSelector);
            return;
        }

        try {
            const response = await fetch(form.getAttribute('action'), {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            await response.json();
            resetForm(form, formSelector);
            modal.open('successfully-sent');
        } catch (error) {
            grecaptcha.reset();
            modal.open('error-message');
        }
    });
}

function validateForm(values) {
    const emailPattern =
        /^[_a-zA-Z0-9]+([._%+-]*[_a-zA-Z0-9])*@(?:[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])*\.[a-z]{2,}|(([0-9]{1,3}\.){3}[0-9]{1,3}|[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])*(\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])*)*\.[a-z]{2,}))$/;

    if (!values.email || !emailPattern.test(values.email)) {
        return false;
    }

    if (!values.text) {
        return false;
    }

    return true;
}

function resetForm(form, formSelector) {
    grecaptcha.reset();
    form.reset();
    removeRecaptchaError(`${formSelector}`);
}
