import { initForms } from '../components/descriptor/initForms';
import { initHashEmail } from '../components/descriptor/initHashEmail';
import { initHashMessage } from '../components/descriptor/initHashMessage';
import { initAutoresizeTextarea } from '../components/descriptor/initAutoresizeTextarea';
import { initJivosite } from '../components/descriptor/initJivosite';

window.addEventListener('load', handleOnLoadPage, false);

function handleOnLoadPage() {
    initAutoresizeTextarea();
    initForms();
    initHashEmail();
    initHashMessage();
    initJivosite();
}
